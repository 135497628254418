import React, { Component } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Impress from "../components/impress"

class Index extends Component {
  render() {
    return (
      <Layout spacing="true">
        <Seo title="Impressum" />
        <Impress />
      </Layout>
    )
  }
}

export default Index
