import React, { Component } from "react"

class Impress extends Component {
  render() {
    return (
      <div className="container max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 pt-4 pb-16 bg-white text-gray-800">
        <h1 className="mt-20 text-3xl font-bold">Impressum</h1>
        <h2 className="text-3xl mt-3 mb-2">Angaben gemäß § 5 TMG</h2>
        <span className="space-y-3">
          <p>
            Inhaber der Webseite:
            <br />
            Eduard Smolarczyk
          </p>
          <p>
            Name und Anschrift des Anbieters:
            <br />
            Eduard Smolarczyk
            <br />
            Unter den Krohgärten 20
            <br />
            35288 Wohratal
          </p>
        </span>
        <h2 className="text-3xl mt-8 mb-2">Kontakt</h2>
        <p>
          <a
            className="text-blue-400 hover:underline"
            href="tel:+49 151 50 583 582"
          >
            +49 151 50 583 582
          </a>
          <br />
          <a
            className="text-blue-400 hover:underline"
            href="mailto:mail@e-smolarczyk.de"
          >
            mail@e-smolarczyk.de
          </a>
        </p>
        <h2 className="text-3xl mt-8 mb-2">Umsatzsteuer</h2>
        <p>
          Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz:
          <br />
          DE 199029717
        </p>
        <h2 className="text-3xl mt-8 mb-2">Streitschlichtung</h2>
        <span className="space-y-3">
          <p>
            Die Europäische Kommission stellt eine Plattform zur
            Online-Streitbeilegung (OS) bereit:{" "}
            <a
              className="text-blue-400 hover:underline"
              href="https://ec.europa.eu/consumers/odr"
              target="_blank"
              rel="noreferrer"
            >
              https://ec.europa.eu/consumers/odr
            </a>
            .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
          </p>
          <p>
            Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </p>
        </span>
        <h2 className="text-3xl mt-8 mb-2">Haftung für Inhalte</h2>
        <span className="space-y-3">
          <p>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
            auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
            §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
            verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
            überwachen oder nach Umständen zu forschen, die auf eine
            rechtswidrige Tätigkeit hinweisen.
          </p>
          <p>
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon
            unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.
          </p>
        </span>
        <h2 className="text-3xl mt-8 mb-2">Haftung für Links</h2>
        <span className="space-y-3">
          <p>
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren
            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar.
          </p>
          <p>
            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
            jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
            zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen.
          </p>
        </span>
        <h2 className="text-3xl mt-8 mb-2">Urheberrecht</h2>
        <span className="space-y-3">
          <p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet.
          </p>
          <p>
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
            wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
            werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
            trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten
            wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          </p>
        </span>
      </div>
    )
  }
}

export default Impress
